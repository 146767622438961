import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout } from '@/utils/routerHelper'
import { NO_RESET_WHITE_LIST } from '@/constants'

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    name: 'Root',
    meta: {
      hidden: true
    },
	  children: [
		  {
			  path: 'home',
			  component: () => import('@/views/home/index.vue'),
			  name: 'home',
			  meta: {
				  hidden: true,
				  title: '首页',
			  }
		  }
	  ]
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: '登录',
      noTagsView: true
    }
  },{
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  },
	{
		path: '/redirect',
		component: Layout,
		name: 'Redirect',
		children: [
			{
				path: '/redirect/:path(.*)',
				name: 'Redirect',
				component: () => import('@/views/Redirect/Redirect.vue'),
				meta: {}
			}
		],
		meta: {
			hidden: true,
			noTagsView: true
		}
	}
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
	{
	  path: '/meituan',
	  name: 'Meituan',
	  component: Layout,
	  redirect: '/meituan/product',
	  meta: {
	    icon: 'vi-ant-design:dashboard-filled',
	    title: "美团管理",
		affix: false
	  },
	  children: [
	    {
	      path: 'product',
	      name: 'Product',
	      component: () => import('@/views/meituan/index.vue'),  
	      meta: {
	        // affix: true,
	        title: "商品管理",
	      },
	    },
		{
		  path: 'oderlist',
		  name: 'oderlist',
		  component: () => import('@/views/meituan/index.vue'),  
		  meta: {
		    // affix: true,
		    title: "订单管理",
		  },
      children: [
        {
          path: 'fastorder',  
          name: 'fastorder',
          component: () => import('@/views/orderCenter/fastOrder/index.vue'),  
          meta: {
            // affix: true,
            title: "快速查询",
          },
        },{
          path: 'quickorder',  
          name: 'quickorder', 
          component: () => import('@/views/orderCenter/saleOrder/index.vue'),  
          meta: {
            // affix: true,
            title: "快速下单",
          }}]
		}
		
	  ],
	},
  {
	  path: '/platform',
	  name: 'platform',
	  component: Layout,
	  redirect: '/platform/yingyong',
	  meta: {
	    icon: 'vi-ant-design:dashboard-filled',
	    title: "数据中心",
		// affix: true
	  },
	  children: [
	    {
	      path: 'yingyong',
	      name: 'yingyong',
	      component: () => import('@/views/platform/index.vue'),  
	      meta: {
	        // affix: true,
	        title: "应用配置",
	      },
	    },
		{
		  path: 'config',
		  name: 'config',
		  component: () => import('@/views/config/index.vue'),  
		  meta: {
		    // affix: true,
		    title: "平台配置",
		  },
		}
		
	  ],
	},
  {
	  path: '/store',
	  name: 'store',
	  component: Layout,
	  redirect: '/store/config',
	  meta: {
	    icon: 'vi-ant-design:dashboard-filled',
	    title: "门店管理",
		  affix: false,
      alwaysShow: true
	  },
	  children: [
	    {
	      path: 'storeList',
	      name: 'storeList',
	      component: () => import('@/views/store/storeList/index.vue'),  
	      meta: {
	        // affix: true,
	        title: "门店列表",
	      },
	    },
		// {
		  // path: 'buyPlatform',
		  // name: 'buyPlatform',
		  // component: () => import('@/views/store/buyPlatform/index.vue'),  
		  // meta: {
		    // affix: true,
		    // title: "平台配置",
		  // },
		// }
	  ],
	},
	{
		path: '/quanxian',
		name: 'QuanXian',
		component: Layout,
		redirect: '/quanxian/user',
		meta: {
		  icon: 'vi-ant-design:dashboard-filled',
		  title: "权限管理"
		},
		children: [
			{
				path: 'user',
				name: 'User',
				component: () => import('@/views/usermanage/index.vue'),  
				meta: {
				  title: "用户管理",
				},
			},
			{
				path: 'menu',
				name: 'Menu',
				component: () => import('@/views/menumanage/index.vue'),  
				meta: {
				title: "菜单管理",
				},
			},
			{
				path: 'role',
				name: 'Role',
				component: () => import('@/views/rolemanage/index.vue'),  
				meta: {
				title: "角色管理",
				},
			},
			{
				path: 'sale',
				name: 'Sale',
				component: () => import('@/views/sales/index.vue'),
				meta: {
					title: "业务员管理",
				},
			},
			{
				path: 'typegoods',
				name: 'TypeGoods',
				component: () => import('@/views/sales/typegoods/index.vue'),
				meta: {
					title: "品种分配",
				},
			}
		],
	}
]

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !NO_RESET_WHITE_LIST.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
